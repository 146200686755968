<template>
    <login></login>
</template>

<script>
import login from "@/components/auth/login"

export default {
    title: "Login",
    components: {
        'login': login
    }
}
</script>
